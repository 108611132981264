import {Dispatch, ReactElement, SetStateAction, useEffect, useState} from "react";
import {Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography} from "@mui/material";
import {Normalizer} from "../../utils/Normalizer";
import {VisuallyHiddenInput} from "../../component/VisuallyHiddenInput";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {REST} from "../../REST";
import {PlotType} from "../../type/PlotType";

export default function RepairPipe(props: any): ReactElement {
    const [filled, setFilled] = props.filled as [boolean, Dispatch<SetStateAction<boolean>>];
    const [content, setContent] = props.content as [any, Dispatch<SetStateAction<any>>];

    const [sectors, setSectors] = useState<string[]>([]);
    const [plots, setPlots] = useState<PlotType[]>([]);

    const [currentSector, setCurrentSector] = useState<string | null>(null);
    const [currentPlot, setCurrentPlot] = useState<PlotType | null>(null);

    useEffect(() => {
        REST.getPlotSectors().then(ps => {
            setSectors(ps);
            setCurrentSector(ps.at(0)!);
            REST.getPlotBySector(ps.at(0)!).then(pp => {
                setPlots(pp);
                setCurrentPlot(pp.at(0)!);
                setContent({"plot": {"id" : pp.at(0)!.id, "sector": ps.at(0)!, "number": pp.at(0)!.number}});
                setFilled(true);
            });
        })
    }, []);

    const handleOnChangeSector = (event: SelectChangeEvent) => {
        setCurrentSector(event.target.value);
        REST.getPlotBySector(event.target.value).then(pp => {
            setPlots(pp);
            setCurrentPlot(pp.at(0)!);
        });
    }

    const handleOnChangePlot = (event: SelectChangeEvent) => {
        const id = BigInt(event.target.value);
        const p = plots.filter(p => BigInt(p.id) === id).at(0)!;
        setCurrentPlot(p);
        setContent({"plot": {"id" : p.id, "sector": p.sector, "number": p.number}});
    }

    return (<>
        <Typography variant="body2" sx={{mt: 2, textAlign: 'center'}}>Место проведения работ:</Typography>

        <FormControl fullWidth required sx={{mt: 2}}>
            <InputLabel id="sector-label">Квартал</InputLabel>
            <Select
                labelId="sector-label"
                id="sector"
                value={currentSector ?? ''}
                label="Квартал"
                onChange={handleOnChangeSector}
            >
                {sectors.map((qq, index) =>
                    <MenuItem value={qq} key={qq}>{qq + " квартал"}</MenuItem>
                )}
            </Select>
        </FormControl>

        <FormControl fullWidth required sx={{mt: 2}}>
            <InputLabel id="plot-label">Участок</InputLabel>
            <Select
                labelId="plot-label"
                id="plot"
                value={currentPlot?.id.toString() ?? ''}
                label="Участок"
                onChange={handleOnChangePlot}
            >
                {plots.map((pt, index) =>
                    <MenuItem value={pt.id.toString()} key={pt.id}>{"№ " + pt.number}</MenuItem>
                )}
            </Select>
        </FormControl>

        <Button sx={{mt: 2}}
                component="label"
                variant="contained"
                tabIndex={-1}
                fullWidth
                disabled //FIXME: enable
                startIcon={<AttachFileIcon/>}
        >
            Прикрепить фото
            <VisuallyHiddenInput
                type="file"
                accept="image/*"
                name={"blob"}

                onChange={(event) => {
                    //setCurrentBlob(event.target.files![0]);
                    //setCurrentName(event.target.files![0].name ?? '');
                }}
            />
        </Button>

    </>)
}