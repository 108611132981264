import React, {ReactElement, useEffect, useState} from "react";
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControlLabel,
    FormGroup,
    InputAdornment,
    List, ListItem,
    ListItemText,
    ListSubheader,
    TextField,
    Typography
} from "@mui/material";
import {REST} from "../REST";
import {RoleType} from "../type/RoleType";
import {PhoneInput} from "../component/PhoneInput";
import {PlotType} from "../type/PlotType";
import {Normalizer} from "../utils/Normalizer";
import {UserType} from "../type/UserType";

export default function UserModal(props: any): ReactElement {
    const [user, setUser] = props.user as [UserType | null, React.Dispatch<React.SetStateAction<UserType | null>>];
    //const [me, setMe] = props.me;
    const [roles, setRoles] = useState<RoleType[]>([]);

    useEffect(() => {
        REST.getRoles().then(rr => {
            setRoles(rr);
        }).catch(e => {

        });
    }, [user])

    const blockUser = () => {
        REST.blockUser(user!.id).then(() => {
            setUser(null);
        });
    }

    return (<Dialog
        fullWidth
        open={user !== null}
        onClose={() => setUser(null)}
        PaperProps={{
            component: 'form'
        }}
    >
        <DialogContent>

            <Box sx={{display: 'flex', alignItems: 'flex-end', mt: 1}}>
                <Avatar sx={{my: 1, mr: 1}} src={REST.BASE + '/api/user/' + user?.id + '/avatar'} alt={user?.fio}/>
                <TextField
                    id={"fio"}
                    label={"ФИО"}
                    name={"fio"}
                    type={"text"}
                    fullWidth
                    slotProps={{inputLabel: {shrink: true}}}
                    defaultValue={user?.fio}
                    disabled
                />
            </Box>

            <TextField id={"phone"} name={"phone"} label={"Телефон"} fullWidth disabled
                       slotProps={{
                           input: {
                               startAdornment: <InputAdornment position="start">+7</InputAdornment>,
                               inputComponent: PhoneInput as any
                           },
                           inputLabel: {shrink: true},
                       }}
                       sx={{mt: 2}}
                       defaultValue={user?.login}
            />

            <FormGroup sx={{mt: 2}}>
                {roles.map((r, i) =>
                    <FormControlLabel key={r.id}
                        control={<Checkbox disabled checked={user?.roles?.some((ur: RoleType) => ur.id === r.id)}/>}
                        label={r.name}/>
                )}
            </FormGroup>

            <Divider sx={{mt: 1}}/>

            <FormGroup>
                <List sx={{bgcolor: 'background.paper'}}
                      subheader={<ListSubheader component="div">Участки</ListSubheader>}>
                    {(user?.plots?.length ?? 0) > 0
                        ? user?.plots?.map((p: PlotType) =>
                            <ListItem alignItems="flex-start" key={p.id}>
                                <ListItemText primary={p.sector + ' квартал, №' + p.number}/>
                            </ListItem>
                        )
                        : <Box sx={{width: '100%', textAlign: 'center', overflow: 'hidden'}}>
                            <Typography variant={'caption'}><i>нет участков</i></Typography></Box>
                    }
                </List>
            </FormGroup>

        </DialogContent>
        <DialogActions sx={{mr: 2, ml: 2, mb: 1}}>
            <Button color={"error"} onClick={() => setUser(null)}>Отмена</Button>
            <Button color={"error"} variant={"contained"} onClick={() => blockUser()}>Заблокировать</Button>
        </DialogActions>
    </Dialog>);
}