import React, {ChangeEvent, Dispatch, FormEvent, ReactElement, SetStateAction, useEffect, useState} from "react";
import {Normalizer} from "../utils/Normalizer";
import {Navigate} from "react-router-dom";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider, FormControl, InputAdornment, InputLabel, OutlinedInput,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {RequestType} from "../type/RequestType";
import {HTMLInput} from "../component/HTMLInput";
import {REST} from "../REST";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {VisuallyHiddenInput} from "../component/VisuallyHiddenInput";
import {exec} from "node:child_process";

export default function AdminRequestModal(props: any): ReactElement {
    const [me, setMe] = props.me;
    const [item, setItem] = props.item as [RequestType | null, Dispatch<SetStateAction<RequestType | null>>];
    const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
    const content = JSON.parse(item?.content ?? '{}');
    const [answer, setAnswer] = useState<any | null>(null);

    const [executor, setExecutor] = useState<string | null>(null);
    const [length, setLength] = useState<string | null>(null);
    const [price, setPrice] = useState<string | null>(null);
    const [material, setMaterial] = useState<string | null>(null);

    useEffect(() => {
        if ((executor?.length ?? 0) > 0 && (length?.length ?? 0) > 0 && (price?.length ?? 0) > 0 && (material?.length ?? 0) > 0) {
            setAnswer({executor: executor, length: length, price: price, material: material});
            setSaveDisabled(false);
        } else {
            setSaveDisabled(true);
        }
    }, [executor, length, price, material])

    if (me.id === undefined || !(Normalizer.hasRole("admin") || Normalizer.hasRole("chairman"))) {
        return (<Navigate to={"/"}/>);
    }

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const newItem: RequestType = {
            id: item!.id,
            type: item!.type,
            content: {},
            author: item!.author,
            publishDate: '',
            acceptedDate: null,
            completedDate: null,
            expired: false,
            answer: answer!
        }
        REST.answerRequest(newItem).then(() => {
            setItem(null);
        });
    }

    const handleOnChangeSimpleAnswer = (event: ChangeEvent<HTMLInputElement>) => {
        setAnswer({"answer": event.target.value});
        if (event.target.value.length > 0) {
            setSaveDisabled(false);
        } else {
            setSaveDisabled(true);
        }
    }

    const handleOnChangeAnswer = (event: ChangeEvent<HTMLInputElement>) => {
        const key: string = event.target.name.toString();
        if (key === 'executor') {
            setExecutor(event.target.value);
        } else if (key === 'length') {
            setLength(event.target.value);
        } else if (key === 'price') {
            setPrice(event.target.value);
        } else if (key === 'material') {
            setMaterial(event.target.value);
        }
    }

    return (
        <Dialog
            fullWidth
            open={item !== null}
            onClose={() => setItem(null)}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit
            }}
        >
            <DialogTitle>
                <Stack direction={"row"} spacing={1} sx={{justifyContent: 'center'}}>
                    <Typography variant={"body1"}
                                sx={{textAlign: "center"}}>{item?.type.name + " " + item?.publishDate + " от " + Normalizer.shortFio(item?.author.fio ?? '')}</Typography></Stack>
            </DialogTitle>
            <DialogContent>
                <Typography variant="body2" sx={{color: 'text.secondary'}}
                            align={'center'}>{(item?.type.code === 'appeal_public' || item?.type.code === 'appeal_private' || item?.type.code === 'statement')
                    ? content.content
                    : ("Место проведения работ: " + (content.plot?.sector ?? 0) + " квартал, участок №" + content.plot?.number)
                }</Typography>

                <Divider sx={{mt: 2}}/>

                {(item?.type.code === 'appeal_public' || item?.type.code === 'appeal_private' || item?.type.code === 'statement')
                    ? <TextField sx={{mt: 2}}
                                 id={"answer"}
                                 label={"Ответ на обращение"}
                                 name={"answer"}
                                 fullWidth
                                 required
                                 multiline
                                 rows={15}
                                 slotProps={{
                                     input: {
                                         inputComponent: HTMLInput as any
                                     },
                                     inputLabel: {shrink: true},
                                 }}
                                 onChange={handleOnChangeSimpleAnswer}
                    />
                    : <>
                        <TextField sx={{mt: 2}}
                                   id={"executor"}
                                   label={"Исполнитель"}
                                   name={"executor"}
                                   type={"text"}
                                   fullWidth
                                   required
                                   onChange={handleOnChangeAnswer}
                        />
                        <FormControl sx={{mt: 1}} variant="outlined" required fullWidth>
                            <InputLabel htmlFor="label">Длина участка</InputLabel>
                            <OutlinedInput
                                id={"length"}
                                label={"Длина участка"}
                                name={"length"}
                                type={"number"}
                                endAdornment={<InputAdornment position="end">м</InputAdornment>}
                                onChange={handleOnChangeAnswer}
                            />
                        </FormControl>
                        <FormControl sx={{mt: 1}} variant="outlined" required fullWidth>
                            <InputLabel htmlFor="price">Стоимость работ</InputLabel>
                            <OutlinedInput
                                id={"price"}
                                label={"Стоимость работ"}
                                name={"price"}
                                type={"number"}
                                endAdornment={<InputAdornment position="end">руб</InputAdornment>}
                                onChange={handleOnChangeAnswer}
                            />
                        </FormControl>
                        <FormControl sx={{mt: 1}} variant="outlined" required fullWidth>
                            <InputLabel htmlFor="material">Использованные материалы</InputLabel>
                            <OutlinedInput
                                id={"material"}
                                label={"Использованные материалы"}
                                name={"material"}
                                type={"text"}
                                multiline
                                rows={2}
                                onChange={handleOnChangeAnswer}
                            />
                        </FormControl>

                        <Button sx={{mt: 1}}
                                component="label"
                                variant="contained"
                                tabIndex={-1}
                                fullWidth
                                disabled={true}
                                startIcon={<FileUploadIcon/>}
                        >
                            Загрузить фото
                            <VisuallyHiddenInput
                                type="file"
                                name={"blob"}
                                onChange={(event) => {
                                    //setCurrentBlob(event.target.files![0]);
                                    //setCurrentName(event.target.files![0].name ?? '');
                                }}
                            />
                        </Button>
                    </>
                }
            </DialogContent>
            <DialogActions sx={{mr: 2, ml: 2, mb: 1}}>
                <Button color={"error"} onClick={() => setItem(null)}>Отмена</Button>
                <Button color={'success'} type="submit" disabled={saveDisabled} variant={"contained"}>Ответить</Button>
            </DialogActions>
        </Dialog>
    );
}